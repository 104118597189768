body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #15192c;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.row.main-block {
  display: flex;
  margin: auto;
  gap: 10px;
  align-items: center;
}
.col.img-block {
  width: 50%;
}
.col.txt-block {
  width: 50%;
}
.main-img {
  height: 500px;
  width: 500px;
  margin: auto;
  position: relative;
}
.main-img img {
  height: 100%;
  width: 100%;
  margin: auto;
}
.row.main-block {
  display: flex;
  margin: auto;
  padding: 4.5%;
  align-items: center;
  background: #15192c;
}
.main-scratch {
  width: 100%;
  height: 100%;
  background: #15192c;
}
.input-group {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  width: 100%;
}
button.btn.btn-outline-primary {
  width: 90px;
  padding: 16px;
  background: transparent;
  border: 3px solid #5f39ec;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  color: white;
  font-size: 18px;
  cursor: pointer;
}
button.btn-outline-primary-plus {
  width: 90px;
  padding: 16px;
  background: transparent;
  border: 3px solid #5f39ec;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
  cursor: pointer;
  font-size: 18px;
}
input.form-control {
  background: transparent;
  border-top: 3px solid #5f39ec;
  border-bottom: 3px solid #5f39ec;
  color: white;
  text-align: center;
  font-size: 18px;
}
h3.heading-txt {
  font-size: 28px;
  text-align: center;
  color: white;
  font-weight: 600;
}

input.form-control {
  flex: 1;
}
.btn.mint {
  width: 96%;

  padding: 14px;
  background: #5f39ec;
  border-radius: 8px;
  text-align: center;
  color: white;
  font-size: 20px;
}

p.img-txt {
  position: absolute;
  bottom: 6%;
  padding: 10px 30px;
  background: black;
  color: white;
  margin: auto;
  left: 22%;
  border: 1px solid white;
  border-radius: 10px;
  font-size: 18px;
}

h3.img-heading {
  position: absolute;
  top: 4%;
  left: 4%;
  padding: 10px 18px;
  background: black;
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .row.main-block {
    display: block;
  }
  .col.img-block {
    width: 100%;
    margin: 20px 0px;
  }
  .col.txt-block {
    width: 100%;
  }
  button.btn.btn-outline-primary {
    width: auto;
  }
  button.btn-outline-primary-plus {
    width: auto;
  }
  input.form-control {
    flex: none;
  }
  .main-img {
    height: 100%;
    width: 100%;
    margin: auto;
    position: relative;
  }
  h3.img-heading {
    position: absolute;
    top: 4%;
    left: 4%;
    padding: 8px 15px;
    background: black;
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    font-size: 14px;
  }
  p.img-txt {
    position: absolute;
    bottom: 4%;
    padding: 8px 16px;
    background: black;
    color: white;
    margin: auto;
    left: 22%;
    border: 1px solid white;
    border-radius: 10px;
    font-size: 14px;
  }
  .input-group {
    display: flex;
    flex-wrap: unset;
    margin: auto;
    width: 100%;
  }
  input.form-control {
    width: 70%;
  }
  .btn.mint {
    width: 92%;
  }
}
